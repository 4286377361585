<!-- eslint-disable vue/no-v-html -->
<template>
  <hgroup class="tf-title-block">
    <h6
      v-if="kicker"
      v-html="kicker"
    />
    <h3
      v-if="title"
      v-html="title"
    />
    <h4
      v-if="subtitle"
      v-html="subtitle"
    />
    <p
      v-if="text"
      class="tf-lead"
      v-html="text"
    />
  </hgroup>
</template>

<script>
export default {
  props: {
    kicker: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
  },
}
</script>
