<template>
  <header
    :class="className"
  >
    <div class="container">
      <img
        alt="logo"
        class="header-logo"
        :src="content.logo_path"
      >
    </div>
  </header>
</template>

<script>
export default {
  computed: {
    content() {
      return this.$store.getters.QUIZDATA
    },
    className() {
      return this.content.class || 'logo-header'
    },
  },
}
</script>
